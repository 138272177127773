jQuery('.m-sub_nav__mobile .a-sub_nav__link--parent.current-page-ancestor li.current-menu-parent').each(function () {
    var list = jQuery(this), select = jQuery(document.createElement('select')).insertBefore(jQuery(this).hide());

    jQuery('a', this).each(function () {
        var target = jQuery(this).attr('target'),
            option = jQuery(document.createElement('option'))
                .appendTo(select)
                .val(this.href)
                .html(jQuery(this).html())
        select.change(function () {
            if (target === '_blank') {
                window.open(jQuery(this).val());
            }
            else {
                window.location.href = jQuery(this).val();
            }
        });
    });
    list.remove();
});