// Stycky Header
window.onload = function() {stickyHeader()};
window.onresize = function() {stickyHeader()};
// window.onscroll = function() {stickyHeader()}; no needed?
window.addEventListener('scroll', stickyHeader);
window.addEventListener('resize', stickyHeader);

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickyHeader() {
  setTimeout(function() {
    // Get the header
    var body = document.body;
    var header = document.getElementById('site-header');
    var headerHeight = header.offsetHeight;

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    if (document.getElementById('site-header').classList.contains("is-sticky")) {
      document.body.classList.add("is-sticky");
      // body.style.padding = headerHeight + "px 0 0 0";
    }
    
    if (window.pageYOffset > 1) {
      header.classList.add("is-on");
    } else {
      header.classList.remove("is-on");
    }
  }, 0);
}
