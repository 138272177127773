jQuery(document).ready(function () {
    jQuery('.m-quote-slider').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        arrows: true,
        fade: true, 
        appendDots: true,
        adaptiveHeight: true
    });
});