jQuery(document).ready(function($) {
    $('.m-accordion__item').on("click", function() {
       /* $('.m-accordion__text').slideUp(); 
        $('.m-accordion__title').removeClass('active'); 
        $(this).children('.m-accordion__text').stop().slideToggle();
        $(this).children('.m-accordion__title').toggleClass('active');*/
        $('.m-accordion__text').slideUp(); 
        $('.m-accordion__title').removeClass('active'); 
        $(this).children('.m-accordion__text').stop().slideDown();
        $(this).children('.m-accordion__title').addClass('active'); 
    });
}) // End jQuery  
