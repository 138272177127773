
var searchLink = document.getElementById('m-search-link');
var mobilesearchLink = document.getElementById('m-search-link-mobile');

if(searchLink) {
	searchLink.addEventListener('click', function(e) {
		e.preventDefault();
		document.getElementById('m-search-layout').classList.add('m-search-layout--visible');
	}, false);
}

if(mobilesearchLink) {
	mobilesearchLink.addEventListener('click', function(e) {
		e.preventDefault();
		document.getElementById('m-search-layout').classList.add('m-search-layout--visible');
	}, false);
}

var searchClose = document.getElementById('m-search-close');

if(searchClose) {
	searchClose.addEventListener('click', function(e) {
		e.preventDefault();
		document.getElementById('m-search-layout').classList.remove('m-search-layout--visible');
	})
}

