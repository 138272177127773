jQuery(document).ready(function ($) {

  jQuery(document).scroll(function () {
    var y = jQuery(this).scrollTop();
    if (y > 150) {
      jQuery('.m-trial__subnav--sticky').fadeIn();
    } else {
      jQuery('.m-trial__subnav--sticky').fadeOut();
    }
  });

  jQuery('.m-trial__subnav--sticky li').click(function() {
    jQuery('.m-trial__subnav--sticky li').removeClass('active'); 
    jQuery(this).addClass('active'); 
  });



});
/*jQuery(document).ready(function () {
  jQuery(document).on("scroll", onScroll);
  
  //smoothscroll
  jQuery('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
      jQuery(document).off("scroll");
      
      jQuery('a').each(function () {
          jQuery(this).removeClass('active');
      })
      jQuery(this).addClass('active');
    
      var target = this.hash,
          menu = target;
      $target = jQuery(target);
      jQuery('html, body').stop().animate({
          'scrollTop': $target.offset().top+2
      }, 500, 'swing', function () {
          window.location.hash = target;
          jQuery(document).on("scroll", onScroll);
      });
  });
  function onScroll(event){
    var scrollPos = jQuery(document).scrollTop();
    jQuery('.m-trial__subnav--sticky a').each(function () {
        var currLink = jQuery(this);
        var refElement = jQuery(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            jQuery('.m-trial__subnav--sticky li a').removeClass("active");
            currLink.addClass("active");
        }
        else{
            currLink.removeClass("active");
        }
    });
}

});*/