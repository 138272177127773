/* accesibility js */
(function (document, window) {
  var styleText = '::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;';
  var unfocus_style = document.createElement('STYLE');

  window.unfocus = function () {
    document.getElementsByTagName('HEAD')[0].appendChild(unfocus_style);

    document.addEventListener('mousedown', function () {
      unfocus_style.innerHTML = styleText + '}';
    });
    document.addEventListener('keydown', function () {
      unfocus_style.innerHTML = '';
    });
  };  

  unfocus.style = function (style) {
    styleText += style;
  };

  unfocus();
})(document, window);

(function ($) {
  $(function () {

    /* ------------------ SHARE LINK POP-UP ------------------ */
    var socialShareLinkInit = function () {
      $('.c-social-share__link').click(function (e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      });
    }

    socialShareLinkInit();

    $(document).bind('ajaxComplete', socialShareLinkInit);
  });
})(jQuery);


jQuery(document).ready(function($) {
  $('#lists___db97446564fa40b66608098208854913_1_0 + span label').html( 'Please send me occasional mailings about bladder cancer.  To order a print copy of Bladder Cancer Basics, please click <a href="https://49906.thankyou4caring.org/pages/bladder-cancer-for-caregivers-mailed-copy-page" target="_blank">here</a>.')
})