document.addEventListener('DOMContentLoaded', function () {
	// Add the 'is-active' class after 3 seconds
	setTimeout(function () {
		var forms = document.querySelectorAll('.m-dfb.m-dfb--form');
		forms.forEach(function (form) {
			form.classList.add('is-active');
		});
	}, 3000);

	// Handle click event for elements with the class 'gform_button'
	var gformButtons = document.querySelectorAll('.gform_button');
	gformButtons.forEach(function (button) {
		button.addEventListener('click', function (event) {
			var forms = document.querySelectorAll('.m-dfb.m-dfb--form');
			forms.forEach(function (form) {
				form.classList.remove('is-active');
			});
		});
	});
});
