jQuery(document).ready(function ($) {
	//
	//Contact Up Form: Update Contact Us message
	//
	if ($('.m-form-contact-us .ctct-message.success.ctct-success').length) {
		$('.m-form-contact-us').addClass('success');
		$('.m-form-contact-us h3').hide();
		$('.m-form-contact-us .ctct-message.success.ctct-success').html(
			'<strong class="thanks">Thank you for getting in touch.</strong> We appreciate you contacting BCAN. One of our colleagues will get back in touch with you soon. Have a great day!'
		);
	}

	//
	// Home Hero Form:
	//
	//Check if the Hero Form was submited
	if (getCookie('home_hero_form') != 1) {
		setTimeout(function () {
			$('.m-dfb.m-dfb--form').addClass('is-active');
		}, 3000);

		$('.m-dfb__close').click(function (event) {
			event.preventDefault();
			$('.m-dfb.m-dfb--form').removeClass('is-active');
		});
	} else {
		// If Hero form was submited, show Donate Today div after 7 days.
		var donate_cookie = getCookie('donate_today_div');

		if (donate_cookie != '') {
			/*var today = new Date();
			var submitted_date = new Date(donate_cookie);
			var Difference_In_Time = today.getTime() - submitted_date.getTime();
			// To calculate the no. of days between two dates
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			console.log(Difference_In_Days);

			if(Difference_In_Days > 7) {
				$('.m-dfb.m-dfb--donate').addClass('is-active');		
			}*/

			$('.m-dfb.m-dfb--donate').addClass('is-active');
		}
	}

	// Debug var
	var debug = true;

	// Form IDs
	var form_ids = {
		footer: '811903',
		general_sign_up_form: '811929',
		big: '811915', //step 2 form
	};

	var form_parents = {
		footer: 'm-form__sign-up',
		home_hero_form: 'm-dfb__form',
		handbook_patients: 'm-handbook-for-patients',
		handbook_caregivers: 'm-handbook-for-caregivers',
	};

	// Element IDs
	var element_ids = {
		first_name: '[id^=first_name_]',
		last_name: '[id^=last_name_]',
		email: '[id^=email_]',
	};

	var cookie_id = 'ctform-submission';

	// 1. Catch CT form submissions.
	$('form.ctct-form').submit(function () {
		// Get form ID to determine source.
		var form_id = $(this).parent().attr('data-form-id'); // Form ID

		// If the submitted form is the big one, avoid storing the cookie.
		if (form_id != form_ids.big) {
			var data = {
				first_name: $(this).find(element_ids.first_name).val(),
				last_name: $(this).find(element_ids.last_name).val(),
				email: $(this).find(element_ids.email).val(),
				form_name: form_id,
				form_parent: $(this).parent().parent().parent().attr('class'),
			};
			setCookie(cookie_id, JSON.stringify(data), 0.25);

			if (debug) console.log('Cookie set');
		}
	});

	// 2. On load, check if page contains the big form.
	if ($('.m-form-big').length) {
		$('.m-form-big').find('.ctct-form-wrapper').addClass('hidden');
		$('*[data-form-id="' + form_ids.big + '"]').removeClass('hidden');

		if (getCookie(cookie_id) != '') {
			var data = $.parseJSON(getCookie(cookie_id)); // Get cookie
			setCookie(cookie_id, '', -1); // Erase cookie
			var big_form = $('.m-form-big').find('form'); // Get the form

			if (debug) {
				console.log('Inserting cookie values in big form');
				console.log(data);
			}

			// Set cookie values
			big_form.find(element_ids.first_name).val(data.first_name);
			big_form.find(element_ids.last_name).val(data.last_name);
			big_form.find(element_ids.email).val(data.email);

			// Set form action based on source form.
			if (data.form_name == form_ids.general_sign_up_form) {
				if (data.form_parent == form_parents.home_hero_form) {
					//Hide form in hero for 30 days.
					setCookie('home_hero_form', 1, 30);

					// Display Donate today div after 7 days.
					var today = new Date();
					var dd = String(today.getDate()).padStart(2, '0');
					var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
					var yyyy = today.getFullYear();

					today = mm + '/' + dd + '/' + yyyy;

					setCookie('donate_today_div', today, 360);
				}
			}
		}

		//Moving "Other" field
		if ($(".m-form-big .ctct-form label:contains('Other')").length) {
			$('.m-form-big .ctct-form').each(function () {
				//Move "Other" Field
				var other_field = $(this)
					.find("label:contains('Other')")
					.parents('.ctct-form-field-text');

				/**
				 * Rearrange checkboxes such that "other" is last
				 * This is mildly convoluted due to how CC Plugin structures its
				 * form html. */
				const other_checkbox_label = $(this).find(
					'.ctct-form-field-checkbox fieldset .ctct-label-right label:contains("Other")'
				);
				const other_checkbox = $(this).find(
					`.ctct-form-field-checkbox fieldset .ctct-checkbox[id^="${other_checkbox_label.attr(
						'for'
					)}"]`
				);
				var checkbox_field = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox input:last + span'
				);
				$(checkbox_field[0]).after(other_field[0]);
				var checkbox_box = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox input[type="checkbox"]:last'
				);
				var other_field_box = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox .ctct-form-field.ctct-form-field-text'
				);

				$(other_checkbox).change(function (e) {
					if (e.target.checked) {
						$(other_field_box[0]).addClass('active');
					} else {
						$(other_field_box[0]).removeClass('active');
					}
				});

				//Zip code: only number and 5 digits

				// var zip_field = $(this).find('.address-zip .ctct-address-zip');
				// $(zip_field[0]).addClass('hidden');
				// $(zip_field[0]).after(
				// 	'<input id="ct-address-zip-number" class="ct-address-zip-number" />'
				// );
				// var zip_number = $(this).find('.ct-address-zip-number');
				// $(zip_number[0]).change(function (e) {
				// 	$(zip_field[0]).val($(this).val());
				// });

				//State: add a drop down

				/*var state_field = $(this).find('.address-state .ctct-address-state');
				$(state_field[0]).addClass('hidden');
				$(state_field[0]).after(`<select class="ct-address-state-select">
											<option></option>
											<option>AL</option>
											<option>AK</option>
											<option>AZ</option>
											<option>AR</option>
											<option>CA</option>
											<option>CO</option>
											<option>CT</option>
											<option>DE</option>
											<option>FL</option>
											<option>GA</option>
											<option>HI</option>
											<option>ID</option>
											<option>IL</option>
											<option>IN</option>
											<option>IA</option>
											<option>KS</option>
											<option>KY</option>
											<option>LA</option>
											<option>ME</option>
											<option>MD</option>
											<option>MA</option>
											<option>MI</option>
											<option>MN</option>
											<option>MS</option>
											<option>MO</option>
											<option>MT</option>
											<option>NE</option>
											<option>NV</option>
											<option>NH</option>
											<option>NJ</option>
											<option>NM</option>
											<option>NY</option>
											<option>NC</option>
											<option>ND</option>
											<option>OH</option>
											<option>OK</option>
											<option>OR</option>
											<option>PA</option>
											<option>RI</option>
											<option>SC</option>
											<option>SD</option>
											<option>TN</option>
											<option>TX</option>
											<option>UT</option>
											<option>VT</option>
											<option>VA</option>
											<option>WA</option>
											<option>WV</option>
											<option>WI</option>
											<option>WY</option>
										</select>`);
				var zip_number = $(this).find('.ct-address-state-select');
				$(zip_number[0]).change(function (e) {
					$(state_field[0]).val($(this).val());
				});*/
			});
		}
	}

	/***/
	/* Add dropdown to form with .ctct-form */
	/***/

	//Moving "Other" field
	if ($('.ctct-form').length) {
		$('.ctct-form').each(function () {
			//Move "Other" Field
			if ($(".ctct-form label:contains('Other')").length) {
				var other_field = $(this)
					.find("label:contains('Other')")
					.parents('.ctct-form-field-text');
				/**
				 * Rearrange checkboxes such that "other" is last
				 * This is mildly convoluted due to how CC Plugin structures its
				 * form html. */
				const other_checkbox_label = $(this).find(
					'.ctct-form-field-checkbox fieldset .ctct-label-right label:contains("Other")'
				);
				const other_checkbox = $(this).find(
					`.ctct-form-field-checkbox fieldset .ctct-checkbox[id^="${other_checkbox_label.attr(
						'for'
					)}"]`
				);

				var checkbox_field = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox input:last + span'
				);
				$(checkbox_field[0]).after(other_field[0]);
				var checkbox_box = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox input[type="checkbox"]:last'
				);
				var other_field_box = $(this).find(
					'.ctct-form-field-required.ctct-form-field.ctct-form-field-checkbox .ctct-form-field.ctct-form-field-text'
				);

				$(other_checkbox).change(function (e) {
					if (e.target.checked) {
						$(other_field_box[0]).addClass('active');
					} else {
						$(other_field_box[0]).removeClass('active');
					}
				});
			}

			// $(".js-form-ctct .ctct-form .ctct-form-field.ctct-form-field-checkbox input[type='checkbox'].ctct-checkbox").prop("checked", false);

			// if($(".js-form-ctct .ctct-form label:contains('Please send me')").length) {
			// 	console.log('Please send me field active');
			// 	var pleaseSendMeField = $(this).find("label:contains('Please send me')").attr('for');
			// 	$('input#' + pleaseSendMeField).prop("checked", true);
			// }

			//Zip code: only number and 5 digits

			var zip_field = $(this).find('.address-zip .ctct-address-zip');
			$(zip_field[0]).addClass('hidden');
			$(zip_field[0]).after(
				'<input id="ct-address-zip-number" class="ct-address-zip-number" />'
			);
			var zip_number = $(this).find('.ct-address-zip-number');
			$(zip_number[0]).change(function (e) {
				$(zip_field[0]).val($(this).val());
			});

			//State: add a drop down

			var state_field = $(this).find('.address-state .ctct-address-state');
			$(state_field[0]).addClass('hidden');
			$(state_field[0]).after(`<select class="ct-address-state-select">
										<option></option>
										<option>AL</option>
										<option>AK</option>
										<option>AZ</option>
										<option>AR</option>
										<option>CA</option>
										<option>CO</option>
										<option>CT</option>
										<option>DE</option>
										<option>FL</option>
										<option>GA</option>
										<option>HI</option>
										<option>ID</option>
										<option>IL</option>
										<option>IN</option>
										<option>IA</option>
										<option>KS</option>
										<option>KY</option>
										<option>LA</option>
										<option>ME</option>
										<option>MD</option>
										<option>MA</option>
										<option>MI</option>
										<option>MN</option>
										<option>MS</option>
										<option>MO</option>
										<option>MT</option>
										<option>NE</option>
										<option>NV</option>
										<option>NH</option>
										<option>NJ</option>
										<option>NM</option>
										<option>NY</option>
										<option>NC</option>
										<option>ND</option>
										<option>OH</option>
										<option>OK</option>
										<option>OR</option>
										<option>PA</option>
										<option>RI</option>
										<option>SC</option>
										<option>SD</option>
										<option>TN</option>
										<option>TX</option>
										<option>UT</option>
										<option>VT</option>
										<option>VA</option>
										<option>WA</option>
										<option>WV</option>
										<option>WI</option>
										<option>WY</option>
									</select>`);
			var zip_number = $(this).find('.ct-address-state-select');
			$(zip_number[0]).change(function (e) {
				$(state_field[0]).val($(this).val());
			});
		});
	}
});

// Cookie functions.
function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

// Restricts input for the set of matched elements to the given inputFilter function.
(function ($) {
	$.fn.inputFilter = function (inputFilter) {
		return this.on(
			'input keydown keyup mousedown mouseup select contextmenu drop',
			function () {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (this.hasOwnProperty('oldValue')) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
					this.value = '';
				}
			}
		);
	};
})(jQuery);

jQuery(document).ready(function ($) {
	$('#ct-address-zip-number').inputFilter(function (value) {
		return /^\d*$/.test(value) && (value === '' || parseInt(value) <= 99999); // Allow digits only, using a RegExp
	});
});
