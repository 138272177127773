document.addEventListener('DOMContentLoaded', function (e) {
	let lazyloadImages;

	if ('IntersectionObserver' in window) {
		lazyloadImages = document.querySelectorAll('.has-background.lazy');

		const imageObserver = new IntersectionObserver(function (
			entries,
			observer
		) {
			entries.forEach(
				function (entry) {
					if (entry.isIntersecting) {
						let image = entry.target;
						image.classList.remove('lazy');
						imageObserver.unobserve(image);
					}
				},
				{ rootMargin: '0px 0px 25px 0px' }
			);
		});

		lazyloadImages.forEach(function (image) {
			imageObserver.observe(image);
		});
	} else {
		let lazyloadThrottleTimeout;
		lazyloadImages = document.querySelectorAll('.has-backround.lazy');

		function lazyload() {
			if (lazyloadThrottleTimeout) {
				clearTimeout(lazyloadThrottleTimeout);
			}
			lazyloadThrottleTimeout = setTimeout(function () {
				let scrollTop = window.pageYOffset;
				lazyloadImages.forEach(function (img) {
					if (img.offsetTop < window.innerHeight + scrollTop) {
						img.src = img.dataset.src;
						img.classList.remove('lazy');
					}
				});
				if (lazyloadImages.length == 0) {
					document.removeEventListener('scroll', lazyload);
					window.removeEventListener('resize', lazyload);
					window.removeEventListener('orientationChange', lazyload);
				}
			}, 20);
		}

		document.addEventListener('scroll', lazyload);
		window.addEventListener('resize', lazyload);
		window.addEventListener('orientationChange', lazyload);
	}
});
