window.archiveScripts = {};

let $filtersContainer = document.querySelector('.o-dynamic__filters');

if ( $filtersContainer ) {
  window.addEventListener('DOMContentLoaded', function() {
    archiveScripts.app.setCurrentLocation();
    archiveScripts.app.initialize();
  });

  // used when navigating back to the listing, so that it reloads
  // with the previously selected filters
  window.addEventListener('load', function() {
    archiveScripts.app.reloadWithFilters();
  });
}

(function(archiveScripts, filtersContainer, $) {
  archiveScripts = archiveScripts || {};

  const selectors = {
    geolocationNag: '.o-dynamic__filters--geolocation-nag',
    postsContainer: '.a-container__posts-listing',
    featuredPostsContainer: '.a-container__posts-listing-featured',
    resetButton: '.o-dynamic__filters--row-reset button',
    submitButton: '.o-dynamic__filters--row-submit button',
    dropdownBoxes: '.o-dynamic__filters--row-dropdown select',
    multiStateToggle: '.m-trial__toggle--wrapper [name=show-multi-state]'
  };

  let $container = $(filtersContainer),
    $geolocationNag = $(selectors.geolocationNag),
    $resetButton = $(selectors.resetButton),
    $submitButton = $(selectors.submitButton),
    $dropdownBoxes = $(selectors.dropdownBoxes),
    $multiStateToggle = $(selectors.multiStateToggle);

  const loaderHtml = '<div class="ajax-loader"><span class="dashicons dashicons-update"></span></div>';

  const app = {
    postType: $container.data('type'),
    paged: parseInt($container.data('paged')),
    maxPages: parseInt($container.data('max')),
    archiveUrl: $container.data('archive'),
    baseUrl: window.location.href.split('/page/').shift().trim(),
    isGeolocationDenied: false,
    geolocationNag: $(selectors.geolocationNag),
    postsListingHtml: $(selectors.postsContainer).html(),
    featuredListingHtml: $(selectors.featuredPostsContainer).html(),
    ajaxParams: null,
    filtersHistory: 'bcan-filters',
    filtersChangedClass: 'filters-changed',
    locationNagSession: 'bcan-location-nag',

    initialize: function() {
      // temporarily replacing postsListingHtml with the ajax loader, because
      // when coming back after clicking any single post we want to reload
      // the posts list with whatever filters were applied before
      $(selectors.postsContainer).html(loaderHtml);
      $(selectors.featuredPostsContainer).html('');

      this.checkGeolocationPermissions();
      this.closeGeolocationPopupHandler();
      this.resetHandler();
      this.submitHandler();
      this.filtersChangedHandler();
      this.paginationHandler();

      // for table results sorting
      this.tableSortHandler();
    },

    filtersChanged: function() {
      let setCount = 0;

      if ($dropdownBoxes.length) {
        $dropdownBoxes.each(function() {
          // the sort by dropdown doesn't have an empty option
          let unsetValue = $(this).data('filter') === 'order' ? 'distance' : '';

          if ($(this).val() !== unsetValue) {
            setCount++;
          }
        });
      }

      if ( this.paged > 1 ) {
        setCount++;
      }

      if (setCount > 0) {
        $container.get(0).classList.add(this.filtersChangedClass);
      }
      else {
        $container.get(0).classList.remove(this.filtersChangedClass);
      }

      return setCount > 0;
    },

    reloadWithFilters: function() {
      // check history to check for previously used filters (using localstorage here)
      this.historyHandler();

      this.filtersChangedHandler();

      if ( this.filtersChanged() ) {
        this.ajaxReload();
      }
      else {
        // no filters previously used, we restore the posts listing coming from the template
        $(selectors.postsContainer).html(this.postsListingHtml);
        $(selectors.featuredPostsContainer).html(this.featuredListingHtml);
      }
    },

    historyHandler: function() {
      // read localstorage history state to re apply filters and pagination variables
      let historyState = localStorage.getItem(this.filtersHistory);
      if (historyState) {
        historyState = JSON.parse(historyState);
        if (historyState) {
          this.ajaxParams = historyState;
        }
      }

      // check if we were referred here by a single interactive state page
      const $statesDropdown = document.querySelector('select[data-filter=state]');
      const states = Array.from($statesDropdown.options).map(option => option.value);

      let segments = document.referrer.split('/');
      const selectedState = segments.pop() || segments.pop();

      const stateIndex = states.map(state => state.toLowerCase()).indexOf(selectedState);
      if (stateIndex !== -1) {
        const ajaxParams = this.ajaxParams;
        if (ajaxParams) {
          ajaxParams.state = states[stateIndex];
          ajaxParams.order = 'date'; // hard to order by distance for this case
          this.ajaxParams = ajaxParams;
        }
      }

      // check if multi state was checked
      // const multiState = localStorage.getItem(this.multiStateToggle);

      if (this.ajaxParams) {
        this.paged = this.ajaxParams.paged ?? 1;
      }

      // also set filters to what they were in the history state
      this.resetHistoryFilters();

      // and now we can reload the resultset with the restored filters
      this.ajaxReload();
    },

    tableSortHandler: function() {
      const self = this;

      const $sortByElems = $(selectors.postsContainer).find('.posts-listing-inner thead .m-page-header');

      if ($sortByElems.length) {
        $sortByElems.each(function() {
          $(this).get(0).addEventListener('click', function() {
            const sortBy = $(this).data('sortby');
            const sortOrder = $(this).data('sortorder');

            self.tableSort(sortBy, sortOrder);

            const newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
            $(this).data('sortorder', newSortOrder);
          });
        });
      }

      // in-place sorting for featured trials
      const featuredTrialsTable = $(selectors.featuredPostsContainer).find('.posts-listing-inner').get(0);
      if (featuredTrialsTable) {
        self.makeTableSortable(featuredTrialsTable);
      }
    },

    // taken from https://ourcodeworld.com/articles/read/620/how-to-sort-a-table-in-the-client-side-with-pure-javascript-or-jquery
    makeTableSortable: function(table){
      const self = this;

      let th = table.tHead, i;
      th && (th = th.rows[0]) && (th = th.cells);

      if (th) {
        i = th.length;
      }
      else {
        return; // if no `<thead>` then do nothing
      }

      // Loop through every <th> inside the header
      while (--i >= 0) (function (i) {
        let dir = 1;

        // Append click listener to sort
        th[i].addEventListener('click', function () {
          self.sortTableInPlace(table, i, (dir = 1 - dir));
        });
      }(i));
    },

    sortTableInPlace: function (table, col, reverse) {
      let tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
        tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
        i;

      reverse = -((+reverse) || -1);

      // Sort rows
      tr = tr.sort(function (a, b) {
        // `-1 *` if want opposite order
        return reverse * (
          // Using `.textContent.trim()` for test
          a.cells[col].textContent.trim().localeCompare(
            b.cells[col].textContent.trim()
          )
        );
      });

      for (i = 0; i < tr.length; ++i) {
        // Append rows in new order
        tb.appendChild(tr[i]);
      }
    },

    filtersChangedHandler: function() {
      const self = this;

      $dropdownBoxes.each(function() {
        // the sort by dropdown doesn't have an empty option
        let unsetValue = $(this).data('filter') === 'order' ? 'distance' : '';

        $(this).get(0).addEventListener('change', function() {
          if (this.value !== unsetValue) {
            $container.get(0).classList.add(self.filtersChangedClass);
          }
          else {
            if (! self.filtersChanged()) {
              $container.get(0).classList.remove(self.filtersChangedClass);
            }
          }
        });
      });
    },

    resetHandler: function() {
      const self = this;

      if ( $resetButton.length ) {
        $resetButton.get(0).addEventListener('click', function() {
          $dropdownBoxes.each(function() {
            $(this).get(0).selectedIndex = 0;
          });

          self.paged = 1;
          self.baseUrl = self.archiveUrl;
          self.pushHistoryState();
          self.clearAjaxParams();
          self.ajaxReload();
        });
      }
    },

    submitHandler: function() {
      const self = this;

      if ($submitButton.length) {
        $submitButton.get(0).addEventListener('click', function() {
          self.paged = 1;
          self.baseUrl = self.archiveUrl;
          self.pushHistoryState();
          self.ajaxReload();
        });
      }
    },

    searchHandler: function() {
      this.resetSearchQuery();
    },

    paginationHandler: function() {
      const self = this;
      const $paginationLinks = $('.a-container__posts-listing .m-pagination li a');

      if ( $paginationLinks.length ) {
        $paginationLinks.each(function() {
          $(this).get(0).addEventListener('click', function(e) {
            e.preventDefault();

            const href = e.target.getAttribute('href');
            window.scrollTo(0,0);
            const pagedUrl = href.match(/\/page\/(\d+)/);
            if (pagedUrl) {
              self.paged = parseInt(pagedUrl[1]) || 1;
            }
            else {
              self.paged = 1;
            }

            self.clearAjaxParams();
            self.pushHistoryState();
            self.ajaxReload();
          });
        });
      }
    },

    resetSearchQuery: function() {
      this.paged = 1;
      this.baseUrl = window.location.href.split('/page/').shift().trim();
      this.clearAjaxParams();
      this.pushHistoryState();
      this.ajaxReload();
    },

    clearAjaxParams: function() {
      this.ajaxParams = null;

      // also clear localstorage persisted filters
      localStorage.setItem(this.filtersHistory, JSON.stringify({}));
    },

    resetHistoryFilters: function() {
      const self = this;

      $dropdownBoxes.each(function () {
        const filter = $(this).data('filter');

        if (self.ajaxParams && self.ajaxParams[filter]) {
          if (self.ajaxParams[filter].hasOwnProperty('id')) {
            $(this).val(self.ajaxParams[filter].id);
          }
          else {
            $(this).val(self.ajaxParams[filter]);
          }
        }
      });

      // also set the multi-state toggle
      if (self.ajaxParams && self.ajaxParams['multi-state']) {
        $multiStateToggle.prop('checked', true);
      }
    },

    pushHistoryState: function(url) {
      const ajaxParams = this.getAjaxParams();
      localStorage.setItem(this.filtersHistory, JSON.stringify(ajaxParams));
    },

    getAjaxParams: function() {
      if ( this.ajaxParams ) {
        // return this.ajaxParams;
      }

      const params = {
        post_type: this.postType,
        status: 'publish',
        paged: this.paged,
      };

      // check filters
      $dropdownBoxes.each(function () {
        const filter = $(this).data('filter');

        if ( $(this).data('tax') ) {
          params[filter] = {
            tax: $(this).data('tax'),
            id: $(this).val()
          };
        }
        else {
          params[filter] = $(this).val();
        }
      });

      // new multi-state results toggle
      if ($multiStateToggle.is(':checked')) {
        params['multi-state'] = true;
      }

      // include the base archive url to set correct pagination links
      params.baseurl = this.baseUrl;

      // lastly, include user's coords
      params.coords = this.getCurrentCoords();

      this.ajaxParams = params;

      return params;
    },

    ajaxReload: function() {
      const self = this;
      const ajaxParams = self.getAjaxParams();

      $(selectors.postsContainer).html(loaderHtml);
      $(selectors.featuredPostsContainer).html('').hide();

      $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          action: 'refresh_filter',
          parameters: ajaxParams
        },
        success: function(response) {
          if ( response.success ) {
            if (response.data.featured_count === 0 && response.data.html_count === 0) {
              $(selectors.postsContainer).replaceWith(response.data.html);
            }
            else {
              if (response.data.featured_count > 0) {
                $(selectors.featuredPostsContainer).replaceWith(response.data.featured).show();
              }

              if (response.data.html_count > 0) {
                $(selectors.postsContainer).replaceWith(response.data.html);
              }
              else {
                $(selectors.postsContainer).html('');
              }
            }

            // also remove class to disable reset button if no filters are set
            if (! self.filtersChanged()) {
              $container.get(0).classList.remove(self.filtersChangedClass);
            }

            self.filtersChangedHandler();
            self.paginationHandler();
            self.tableSortHandler();
          }
        },
        error: function (errorThrown) {
          console.error(errorThrown);
        }
      });
    },

    tableSort: function(sortBy, sortOrder) {
      const self = this;
      const ajaxParams = self.getAjaxParams();

      $.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
          action: 'sort_inner_results',
          parameters: { ...ajaxParams, order: sortOrder, sortby: sortBy }
        },
        success: function(response) {
          if (response.success) {
            $(selectors.postsContainer).find('tbody').html(response.data.html);
          }
        },
        error: function (errorThrown) {
          console.error(errorThrown);
        }
      });
    },

    closeGeolocationPopupHandler: function() {
      const self = this;

      if ($geolocationNag) {
        $geolocationNag.get(0).querySelectorAll('span, a').forEach(function(node) {
          node.addEventListener('click', function() {
            $geolocationNag.get(0).style.display = 'none';
            sessionStorage.setItem(self.locationNagSession, '1');
          });
        });
      }
    },

    setGeolocationVisibility: function() {
      const hasLocationNagSession = sessionStorage.getItem(this.locationNagSession);
      $geolocationNag.get(0).style.display = this.isGeolocationDenied && ! hasLocationNagSession ? 'block' : 'none';
    },

    checkGeolocationPermissions: function() {
      const self = this;

      if (navigator.permissions) {
        navigator.permissions.query({
          name: 'geolocation'
        }).then(function(result) {
          self.isGeolocationDenied = (result.state === 'denied');
          self.setGeolocationVisibility();

          result.onchange = function() {
            self.isGeolocationDenied = (result.state === 'denied');
            self.setGeolocationVisibility();
          }
        });
      }
      else {
        // workaround for Safari
        navigator.geolocation.watchPosition(
          function(position) {
            self.isGeolocationDenied = false;
            self.setGeolocationVisibility();
          },
          function(positionError) {
            self.isGeolocationDenied = true;
            self.setGeolocationVisibility();
          },
          { timeout: 0 }
        )
      }
    },

    // TODO: it would be safer to do this sycnronously using async/await or similar
    setCurrentLocation: function() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(geo) {
          localStorage.setItem('bcan-lat', geo.coords.latitude);
          localStorage.setItem('bcan-lng', geo.coords.longitude);
        });
      }
      else {
        localStorage.setItem('bcan-lat', '');
        localStorage.setItem('bcan-lng', '');
      }
    },

    getCurrentCoords: function() {
      const lat = localStorage.getItem('bcan-lat');
      const lng = localStorage.getItem('bcan-lng');

      if (lat && lng) {
        return { lat, lng }
      }

      return false
    }
  };

  $.extend(archiveScripts, {
    app: app
  });

}).apply(this, [window.archiveScripts, $filtersContainer, jQuery]);
