jQuery(document).ready(function ($) {
	// MOBILE MENU
	jQuery('.a-main_nav__toggle__button').on("click", function () {
		jQuery(this).toggleClass('active');
		jQuery('.m-main_nav__mobile').fadeToggle("fast");
		jQuery('#site-header').toggleClass("active");
	});

	jQuery('.m-main_nav__mobile .a-main_nav__title').on("click", function (evt) {
		evt.stopPropagation();
		event.preventDefault();
		jQuery('.m-main_nav__links_list__child_list').slideUp("fast");
		jQuery(this).parent().children('.m-main_nav__links_list__child_list').stop().slideToggle("fast");
		jQuery(this).parent().toggleClass('active').siblings().removeClass('active');
	});

	jQuery('.m-main_nav__mobile li .menu-item-has-children>a').on("click", function (evtt) {
		evtt.stopPropagation();
		event.preventDefault();
		jQuery('.m-main_nav__links_list__child_list--sublist').slideUp("fast");
		jQuery(this).parent().children('.m-main_nav__links_list__child_list--sublist').stop().slideToggle("fast");
		jQuery(this).parent().toggleClass('activesub').siblings().removeClass('activesub');
	});

	jQuery('.m-page-header__buttons--text-size').click(function () {
		jQuery(this).toggleClass('active');
		jQuery('body').toggleClass('enlarge');
	});
});